import { getDefaultLimitRepresentation } from "common/utils/limitRepresentation";
import { CleaningLimitPolicyResidueType } from "./enums";
import { PolicyConfigUI } from "./types";
import { DEFAULT_UNITS } from "./utils/default-units";

export const defaultCleaningPolicy: PolicyConfigUI = {
  useDosage: false,
  useToxicity: false,
  useProductLimit: false,
  useARL: false,
  arl: { value: 10, unit: DEFAULT_UNITS.L5 },
  residueLimit: { value: 10, unit: DEFAULT_UNITS.L3 },
  useResidueLimit: false,
  ignoreCombo: false,
  useGlobalRecoveryPercentage: false,
  globalRecoveryPercentage: 100,
  residueType: CleaningLimitPolicyResidueType.Chemical,
  useSAR: false,
  limitRepresentation: getDefaultLimitRepresentation(),
  useFirstAvailableCriteria: false,
  intermediateArl: 0,
  activesArl: { value: 10, unit: DEFAULT_UNITS.L5 },
  useSampleLimit: false,
  swabSampleLimit: 10,
  rinseSampleLimit: 10
};

export const ROOM_GRADE_ID = "roomGradeId";
