const userErrors = new Set([
  "UserBlockedError",
  "ChangePasswordOnFirstLogin",
  "ChangePasswordOnPasswordExpiry",
  "PasswordMinLengthError",
  "PasswordNotComplexError",
  "PasswordAlreadyUsed",
  "InvalidEmailOrPasswordError",
  "RequestingUserNotfound",
  "LoginAttemptsExceeded",
  "InvalidToken"
]);

export function isUserError(error: { [index: string]: any }) {
  const name = error && error.errName;
  return userErrors.has(name);
}

export function nothingChangeError() {
  return {
    errName: "NothingToChangeError",
    name: "Nothing to change",
    message:
      "No changes have been made from the current values. Please check all fields and try again."
  };
}

export function naivePaginate<T = any>(
  data: T[],
  o?: {
    pagination?: {
      pageNumber: number;
      pageLimit: number;
    };
    unpaginated?: boolean;
  }
) {
  if (o?.unpaginated) {
    return data;
  }
  const { pageNumber, pageLimit } = (o && o.pagination) || {
    pageNumber: 1,
    pageLimit: 10
  };

  const from = (pageNumber - 1) * pageLimit;

  const dataPaginated = data.slice(from, from + pageLimit);

  return dataPaginated;
}
