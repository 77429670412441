import { Form, Icon, Input } from "antd";
import { FormComponentProps, FormCreateOption } from "antd/lib/form/Form";
import { LoginFormButton } from "client/scenes/Auth/styles";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  isLoading: boolean;
  onSubmit: (email: string, password?: string) => void;
  ssoEnabled: boolean;
};

const FormItem = Form.Item;

const LoginFormComponent = (props: Props & FormComponentProps) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.onSubmit(values.userId, values.password);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const { ssoEnabled } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormItem className="pb-2" label="EMAIL/EMPLOYEE ID" required={false} colon={false}>
        {getFieldDecorator("userId", {
          rules: [
            {
              required: true,
              message: "Please input your E-mail!"
            }
          ],
          validateTrigger: "onBlur"
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="account@leucinetech.com"
          />
        )}
      </FormItem>
      {ssoEnabled ? null : (
        <>
          <FormItem label="PASSWORD" required={false} colon={false}>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your Password!" }]
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="*******"
              />
            )}
          </FormItem>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px"
            }}
          >
            <Link to="/forgot-password">Forgot Password ?</Link>
          </div>
        </>
      )}

      <div
        style={{
          display: `grid`,
          marginTop: `50px`,
          gridAutoFlow: `column`
        }}
      >
        {ssoEnabled ? null : (
          <div style={{ alignSelf: "center" }}>
            <Link to="/register" style={{ color: "#666666" }}>
              New User? <span style={{ color: "#1890ff" }}>Register</span>
            </Link>
          </div>
        )}

        <LoginFormButton
          style={{ justifySelf: `end` }}
          type="primary"
          htmlType="submit"
          loading={props.isLoading}
          className="mt-2"
        >
          Login
        </LoginFormButton>
      </div>
    </Form>
  );
};

const createOptions: FormCreateOption<any> = {};
export const LoginForm = Form.create(createOptions)(LoginFormComponent);
