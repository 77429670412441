import { Icon, Tooltip, Input } from "antd";
import Form, { FormComponentProps, FormCreateOption } from "antd/lib/form/Form";
import React from "react";
import { Link } from "react-router-dom";
import { LoginFormButton } from "client/scenes/Auth/styles";

const FormItem = Form.Item;

type Props = {
  onSubmit: (userId: string, token: string) => void;
};

const RegisterUserFormComponent = (props: Props & FormComponentProps) => {
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        await props.onSubmit(values.userId, values.token);
        props.form.resetFields();
      }
    });
  };

  const secretKeyLabel = (
    <span className="secretKeyLabel">
      <span className="secretKeyTitle">SECRET KEY</span>
      <Tooltip title="You would have received a Secret Key from your Administrator. It is needed to ensure that it is in fact you who is trying to register.">
        <Icon
          type="info-circle"
          theme="filled"
          style={{ marginLeft: `5px`, verticalAlign: `0.075em` }}
        />
      </Tooltip>
    </span>
  );

  const { getFieldDecorator } = props.form;

  return (
    <Form onSubmit={handleSubmit}>
      <FormItem label="EMPLOYEE ID" required={false} colon={false} className="pb-2">
        {getFieldDecorator("userId", {
          rules: [
            {
              required: true,
              message: "Please Employee Id of the user"
            }
          ]
        })(<Input placeholder="Enter your employee ID" />)}
      </FormItem>
      <FormItem
        label={secretKeyLabel}
        required={false}
        colon={false}
        // style={{ padding: `10px 0px 8px 0px` }}
      >
        {getFieldDecorator("token", {
          rules: [
            {
              required: true,
              message: "Please Enter the Secret Key"
            }
          ]
        })(<Input.Password placeholder="Enter your unique Secret Key" />)}
      </FormItem>
      <div
        style={{
          display: "grid",
          marginTop: "50px",
          gridAutoFlow: "column"
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <Link to="/" style={{ color: "#666666" }}>
            Already a User? <span style={{ color: "#1890ff" }}>Sign In</span>
          </Link>
        </div>
        <LoginFormButton style={{ justifySelf: "end" }} type="primary" htmlType="submit">
          Set Password
        </LoginFormButton>
      </div>
    </Form>
  );
};

const createOptions: FormCreateOption<any> = {};
export const RegisterUserForm = Form.create(createOptions)(RegisterUserFormComponent);
