import { Button, Icon, Input } from "antd";
import Form, { FormComponentProps, FormCreateOption } from "antd/lib/form";
import Title from "antd/lib/typography/Title";
import * as apiIndependentFunctions from "client/services/apiFunctions";
import { openNotificationWithIcon } from "client/utils";
import React, { useState } from "react";
import styled from "styled-components";

import { getUserErrorMessage } from "../../../../services/api";
import { AuthPageTemplate } from "../Common/AuthPageTemplate";

export enum NotificationTypes {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error"
}

type S = {
  isLoading: boolean;
};

type Props = FormComponentProps;

const PasswordExpiryTitle = styled(Title).attrs({
  id: "password-expiry-title"
})`
  font-weight: 500;
  margin-bottom: 0.5em;
  color: ${props => props.theme.alphaColors.black("0.85")};
  font-size: 1.75rem;
`;

const GenNewLinkButton = styled(Button).attrs({
  id: "gen-new-link-button"
})`
  border-radius: 4px;
  width: 10rem;
  height: 2.1rem;
  background-color: ${props => props.theme.alphaColors.dodgerBlue()};
  color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.alphaColors.dodgerBlue("0.58")};
  letter-spacing: 0.03rem;
`;

const FormItem = Form.Item;
const pageTitle = <PasswordExpiryTitle level={3}>Link has expired!</PasswordExpiryTitle>;
const pageSubTitle = <p>You will need a fresh link </p>;

const PasswordExpiryForm = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const { getFieldDecorator } = props.form;

  const forgotPassword = async (email: string) => {
    setLoading(true);
    try {
      // await forgotPassword(email.toLocaleLowerCase());
      openNotificationWithIcon(
        NotificationTypes.SUCCESS,
        "Reset Link Generated Successfully",
        "Please check you mail for further instructions."
      );
    } catch (err) {
      console.log(err);
      const errorMessage = apiIndependentFunctions.isUserError(err)
        ? getUserErrorMessage(err)
        : "Failed to Generate Reset Link";
      openNotificationWithIcon(NotificationTypes.ERROR, err.message, errorMessage);
    }
    setLoading(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        await forgotPassword(values.email);
        props.form.resetFields();
      }
    });
  };

  const PasswordExpiryFormComponent = (
    <Form onSubmit={handleSubmit}>
      <FormItem label="YOUR EMAIL" required={false} colon={false}>
        {getFieldDecorator("email", {
          rules: [
            {
              type: "email",
              required: true,
              message: "Please input your email!"
            }
          ]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        )}
      </FormItem>
      <div className="d-flex flex-row-reverse mt-10">
        <GenNewLinkButton type="primary" htmlType="submit" loading={isLoading} className="mt-2">
          GET A FRESH LINK
        </GenNewLinkButton>
      </div>
    </Form>
  );

  return (
    <AuthPageTemplate
      title={pageTitle}
      subTitle={pageSubTitle}
      pageForm={PasswordExpiryFormComponent}
    />
  );
};

const createOptions: FormCreateOption<any> = {};
const PasswordExpiry = Form.create(createOptions)(PasswordExpiryForm);
export default PasswordExpiry;
