import { Col } from "antd";
import * as React from "react";
import CleenLogo from "../CleenLogo";
import { CompanyNameTitle, CompanySubtitle, RowWithBackdrop, TradeMarkTextWrapper } from "./styles";

const LeftScreen = () => {
  return (
    <RowWithBackdrop justify="center" align="middle" type="flex">
      <Col span={17}>
        <div className="mt-5">
          <CompanyNameTitle>
            <CleenLogo width={"100%"} logoColor={"white"} />
          </CompanyNameTitle>
          <CompanySubtitle>THE NEW, NEW WAY TO VALIDATE</CompanySubtitle>
        </div>
      </Col>
      <TradeMarkTextWrapper>&#8482;&nbsp;2019 CLEEN</TradeMarkTextWrapper>
    </RowWithBackdrop>
  );
};

export default LeftScreen;
