import { notification, Tooltip } from "antd";
import { PaginationConfig } from "antd/lib/table";
import { PasswordPolicyRules, ProductTier, SessionStorageKeys } from "common/enums";
import { ProductionType } from "common/types/production";
import { Action, Domain } from "common/types/user-role";
import { createLocalDateTimeFormatter } from "common/utils/date-time-format";
import _get from "lodash/get";
import moment from "moment-timezone";
import React from "react";
import { DefaultFormats } from "./Data/DateTimeFormatStore";
import store from "./Data/UserStore";

export const formatKeyString = (str: string) => {
  const strWithoutUnderscore = str.split("_");
  const finalStringWithoutUnderscores = strWithoutUnderscore.join(" ");
  const finalString =
    finalStringWithoutUnderscores.charAt(0).toUpperCase() + finalStringWithoutUnderscores.slice(1);
  return finalString;
};

export const releaseStatus = {
  MayBeReleased: "MAY_BE_RELEASED",
  CanNotBeReleased: "CAN_NOT_BE_RELEASED",
  CanBeReleased: "CAN_BE_RELEASED"
};

export const formatTime = (date: Date | moment.Moment | string, onlyDate?: boolean) => {
  const timestamp = moment(date).unix();
  const defaultFormats = DefaultFormats.defaultFormats;
  const timestampStringFn = createLocalDateTimeFormatter(
    defaultFormats.timezone,
    defaultFormats.dateFormat,
    defaultFormats.timeFormat
  );
  const formattedDateTime = onlyDate
    ? timestampStringFn.formatDate(timestamp)
    : timestampStringFn.formatDateTime(timestamp);
  return formattedDateTime;
};

export const getCurrentTime = () => {
  return formatTime(moment().tz(DefaultFormats.defaultFormats.timezone));
};

export const getTimeFromNow = (date: Date | string) => {
  const defaultFormats = DefaultFormats.defaultFormats;
  const dateTimeFormat = defaultFormats.dateFormat + " " + defaultFormats.timeFormat;
  const givenDate = formatTime(date);
  const now = formatTime(moment().tz(defaultFormats.timezone));

  const diffFromNow = moment(givenDate, dateTimeFormat).from(moment(now, dateTimeFormat));
  return diffFromNow;
};

export const showMultiLineDateTime = (date: string | Date) => {
  const timestamp = moment(date).unix();
  const defaultFormats = DefaultFormats.defaultFormats;
  const timestampStringFn = createLocalDateTimeFormatter(
    defaultFormats.timezone,
    defaultFormats.dateFormat,
    defaultFormats.timeFormat
  );
  const formattedDate = timestampStringFn.formatDate(timestamp);
  const formattedTime = timestampStringFn.formatTime(timestamp);

  return (
    <div>
      <div>
        <b>{formattedDate}</b>
      </div>
      <div>{formattedTime}</div>
    </div>
  );
};

export const RED_COLOR = "#F03434";
export const GREEN_COLOR = "#3D9392";
export const LIGHT_GREEN_COLOR = "#00A8A0";
export const ORANGE_COLOR = "#F89406";
export const LIGHT_YELLOW_COLOR = "#FCB545";

export const BLACK_COLOR = "#212121";

export const DONUT_RED_COLOR = "#e74c3c";
export const DONUT_YELLOW_COLOR = "#f1c40f";
export const DONUT_BLUE_COLOR = "#3498db";
export const DONUT_GREEN_COLOR = "#2ecc71";

export function getErrorMsg(error: { [index: string]: any }) {
  let name;
  let message;
  let errName;
  if (error.name) {
    name = error.name;
  }
  if (error.message) {
    message = error.message;
  }
  if (error.errName) {
    errName = error.errName;
  }
  if (error.errors && Array.isArray(error.errors)) {
    const { errors } = error;
    message = errors.map((e, i) => <div key={i}>{e.constraints[e.property]}</div>);
  }

  switch (errName) {
    case "ArchivedEntryError":
      name = "Data Archived";
      message =
        message +
        ". Please go to the archived list to restore. Or change the ID to create a new " +
        message.split(" ")[0] +
        ".";
      break;
  }

  return {
    name: name || "Error Occurred",
    message
  };
}

export function getAntFormError(error: { [index: string]: any }) {
  const errorObject = {};
  switch (error.errName) {
    case "DuplicateEntryError":
      errorObject[error.property] = {
        errors: [new Error(error.message)]
      };
      return errorObject;

    case "DataValidationError":
      if (error.errors && Array.isArray(error.errors)) {
        const errors_ = error.errors;
        errors_.forEach(err => {
          //TODO typing
          const errMessage: any = Object.values(err.constraints)[0];
          errorObject[err.property] = {
            value: err.value,
            errors: [new Error(errMessage)]
          };
        });
        return errorObject;
      }

    case "SampleChangesError":
      if (error.otherErrors && Array.isArray(error.otherErrors)) {
        const errors_ = error.otherErrors;
        errors_.forEach(err => {
          errorObject[err.key] = {
            errors: [new Error(err.message)]
          };
        });
        return errorObject;
      }

    case "DuplicateBatchNumber":
      errorObject["batch_number"] = {
        errors: [new Error("Batch number must be unique across samples")]
      };
      return errorObject;

    case "PasswordMinLengthError":
      const minLengthErrorText = `Your password must be minimum of ${error.minLength} characters long`;
      const minLengthErrors = [new Error(minLengthErrorText)];
      errorObject["newPassword"] = {
        errors: minLengthErrors
      };
      errorObject["errors"] = (
        <ul>
          <li>{minLengthErrorText}</li>
        </ul>
      );
      return errorObject;

    case "PasswordNotComplexError":
      const errorText = getErrorMessageForComplexPasswordError(error);
      const errors = errorText.map(message => new Error(message));
      errorObject["newPassword"] = { errors };
      errorObject["errors"] = (
        <ul>
          {errorText.map((message, i) => (
            <li key={i}>{message}</li>
          ))}
        </ul>
      );
      return errorObject;

    default:
      return null;
  }
}

export enum PasswordComplexity {
  APLHA_NUMERIC = "PasswordNotNumber",
  CAPITAL_ALPHABET = "PasswordNotCapital",
  SPECIAL_CHAR = "PasswordNotSpecial"
}

export function getErrorMessageForComplexPasswordError(error: any) {
  const passwordComplexitiesNotMet = error.passwordComplexitiesNotMet;
  const messages: string[] = [];
  passwordComplexitiesNotMet.map(passwordComplexityString => {
    if (passwordComplexityString === PasswordComplexity.APLHA_NUMERIC) {
      messages.push("Your password must contain atleast one number and alphabet");
    } else if (passwordComplexityString === PasswordComplexity.CAPITAL_ALPHABET) {
      messages.push("Your password must contain atleast one capital letter");
    } else if (passwordComplexityString === PasswordComplexity.SPECIAL_CHAR) {
      messages.push("Your password must contain atleast one special character");
    }
  });
  return messages;
}

export function getBooleanString(bool: boolean) {
  return bool ? "True" : "False";
}

export function getBooleanFromString(str: string) {
  return JSON.parse(str.toLowerCase());
}

export const units = {
  equipment_surface_area: { default: ["sqcm", "sqin"] },
  pde: {
    default: ["mg", "ug"]
  },
  td: {
    default: ["mg", "ug"]
  }, //dosage
  ldd: { volumetric: ["ml"], units_only: ["units"], default: ["mg", "ug"] },
  ld50: { default: ["mg/kg"] },
  surface_area: {
    default: ["sqcm", "sqin"]
  },

  L3: {
    default: ["mg/sqcm", "ug/sqcm", "mg/sqin", "ug/sqin"]
  },
  L4: { default: ["ug", "mg", "g", "kg"] },
  L5: {
    default: ["ppm"]
  },
  L4_swab: { default: ["mg per swab", "ug per swab"] },
  L4_rinse: { default: ["mg per rinse", "ug per rinse"] },
  swab_limit: { default: ["mg per swab", "ug per swab"] },
  volume: {
    default: ["ml", "L"]
  },
  min_bs: {
    solid: ["mg", "kg"],
    units_only: ["units"],
    volumetric: ["ml", "L"]
  },
  strength: { default: ["mg", "ug"] },
  tablet_weight: {
    default: ["mg"],
    volumetric: ["ml"]
  }
};

export const getUnits = () => {
  return units;
};

export const getDonutToolTip = ({ values, labels, marker }) => (
  <div style={{ padding: "8px" }}>
    {labels.map((label, i) => (
      <div key={i}>
        <svg width="10" height="10" style={{ margin: "0 10px 0 0" }}>
          <rect width="10" height="10" style={{ fill: marker["colors"][i] }} />
        </svg>
        {label} ({values[i]})
      </div>
    ))}
  </div>
);

export const getChartToolTip = chartData => (
  <div style={{ padding: "8px" }}>
    {chartData.map((data, i) => (
      <div key={i}>
        <svg width="10" height="10" style={{ margin: "0 10px 0 0" }}>
          <rect width="10" height="10" style={{ fill: data["line"]["color"] }} />
        </svg>
        {data["name"]}
      </div>
    ))}
  </div>
);

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 11 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
};

export const taskFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
};

type getTableColumns = (
  tableColumns: {
    name?: React.ReactNode;
    column: string;
    className?: string;
    size?: string | number;
    width?: string | number;
  }[]
) => {
  title?: React.ReactNode;
  dataIndex: string;
  className: string;
  key: string;
  width?: string | number;
}[];
export const getTableColumns: getTableColumns = tableColumns =>
  tableColumns.map(c => ({
    title: c.name && <Tooltip title={c.name}>{c.name}</Tooltip>,
    dataIndex: c.column,
    className: `${c.className ? c.className : ""}`,
    key: c.column,
    width: c.size || c.width
  }));

export const groupBy = (xs: any, key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const customPanelStyle = {
  background: "#cde7ff",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden"
};

export const TablesCustomPanelStyle = {
  ...customPanelStyle,
  background: "#f2f2f2"
};

export const getBase64 = (img: Blob, callback: (url: string) => void) => {
  const reader = new FileReader();
  //Since we are using readAsDataUrl, result will always be string
  reader.addEventListener("load", () => reader.result && callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const resetPagination = (): PaginationConfig => ({
  pageSize: 10,
  current: 1
});

export const selectSearchFilter = (input: string, option: any) =>
  Array.isArray(option.props.children)
    ? option.props.children[0].toLowerCase().includes(input.toLowerCase())
    : option.props.children.toLowerCase().includes(input.toLowerCase());

export function getEquipmentGroupSuccessMessage(o: { edited: boolean; id: string }) {
  return `Equipment group ${o.id} ${o.edited ? "updated" : "created"}`;
}

// log function that logs errors, we will expand and define in future to handle different types of errors and log them
export function logError(err: any) {
  console.error(err);
}

export function getRuleExpanded(
  rules: { key: string; value: number }[]
): { key: string; value: number; meaning: string; isPassing: boolean }[] {
  const ruleWithMeaning: {
    key: string;
    value: number;
    meaning: string;
    isPassing: boolean;
  }[] = [];
  rules.forEach(rule => {
    if (PasswordPolicyRules[rule.key]) {
      ruleWithMeaning.push({
        key: rule.key,
        value: rule.value,
        meaning: PasswordPolicyRules[rule.key],
        isPassing: false
      });
    } else if (rule.key === "minPasswordLength") {
      ruleWithMeaning.push({
        key: rule.key,
        value: rule.value,
        meaning: `${rule.value} characters minimum`,
        isPassing: false
      });
    }
  });

  ruleWithMeaning.push({
    key: "passwordsMatch",
    value: 0, //DUMMY VALUE
    meaning: "Passwords Match",
    isPassing: false
  });
  return ruleWithMeaning;
}

export const isNumeric = (str: string): boolean => {
  const regex = /\d/;
  return regex.test(str);
};

export const containsAtleastOneCapitalLetter = (str: string): boolean => {
  const capitalAlphabetRegex = /[A-Z]/;
  return capitalAlphabetRegex.test(str);
};

export const isUserPasswordOfMinLength = (password: string, minPasswordLength: number): boolean => {
  if (password.length >= minPasswordLength) {
    return true;
  }
  return false;
};

export const hasSpecialCharacter = (str: string): boolean => {
  const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  return specialCharacterRegex.test(str);
};
/**
 * Creates an array of array values not included in the other
 * array.
 * The order and references of result values are
 * determined by the first array.
 */
export function difference(listOne: any[], listTwo: any[]) {
  const set1 = new Set(listOne);
  const set2 = new Set(listTwo);
  const diff = new Set([...set1].filter(x => !set2.has(x)));
  return Array.from(diff);
}

export const formatToEllipsisText = (text: string, maxLength?: number) => {
  return (
    <Tooltip title={text}>
      <div className="ellipsis" style={maxLength ? { maxWidth: maxLength } : {}}>
        {text}
      </div>
    </Tooltip>
  );
};

export const formatToEllipsisTextByLength = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return (
      <Tooltip title={text}>
        <div>{`${text.substr(0, maxLength)}...`}</div>
      </Tooltip>
    );
  }
  return text;
};

export const getPaginationInfo = (pageNumber: number, pageLimit: number) => {
  return {
    pageNumber,
    pageLimit
  };
};

const ab2str = buf => {
  const enc = new TextDecoder("utf-8");
  const arr = new Uint8Array(buf);
  return enc.decode(arr);
};

export const fileReader = (file: Blob): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Problem parsing file"));
    };

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else if (typeof reader.result === "object") {
        resolve(ab2str(reader.result));
      } else {
        reject(new Error("Problem parsing file"));
      }
    };

    reader.readAsText(file);
  });
};

export const checkUserAccess = (domain: Domain, action: Action) => {
  const currentUser = store.currentUser;
  return (
    currentUser &&
    (currentUser.roles || []).some(role => _get(role, `permission.${domain}.${action}`, false))
  );
};

export const getCurrentUserRoles = () => {
  const currentUser = store.currentUser;
  return currentUser ? currentUser.roles || [] : [];
};

export const isUserProductionOrQCOnly = () => {
  const currentRoles = getCurrentUserRoles();
  return currentRoles.every(role => role.name === "Production" || role.name === "Quality Control");
};

const notificationStyleByType = {
  error: {
    borderColor: "#ffccc7",
    backgroundColor: "#fff1f0"
  },
  success: {
    borderColor: "#b7eb8f",
    backgroundColor: "#f6ffed"
  },
  info: {
    borderColor: "#91d5ff",
    backgroundColor: "#e6f7ff"
  },
  warning: {
    borderColor: "#ffe58f",
    backgroundColor: "#fffbe6"
  }
};

export const openNotificationWithIcon = (
  type: string,
  message: string,
  description: string,
  duration?: number
) => {
  notification[type]({
    message,
    description,
    duration,
    style: {
      boxShadow: "0 1px 4px rgb(0 0 0 / 10%)",
      border: "solid 1px #fff",
      backgroundColor: "#fff",
      ...notificationStyleByType[type]
    }
  });
};

export const downloadFile = (fileData: File, fileName: string, type: string) => {
  const blob = new Blob([fileData], {
    type
  });
  const reportUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = reportUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const isAPIFacility = () =>
  sessionStorage.getItem(SessionStorageKeys.PRODUCT_TYPE) === ProductionType.API;

export const isLiteTier = () =>
  sessionStorage.getItem(SessionStorageKeys.FACILITY_TIER) === ProductTier.Lite;
