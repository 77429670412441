import styled from "styled-components";
import { Row } from "antd";
import backdrop from "./images/banner.png";

export const RowWithBackdrop = styled(Row).attrs({
  id: "row-with-backdrop"
})`
  width: 100%;
  height: 100%;
  background-image: url(${backdrop});
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    background-color: ${props => props.theme.colors.keppel};
    background-image: linear-gradient(146deg, ${props => props.theme.colors.keppel}, #1d2133);
  }
`;

export const CompanyNameTitle = styled.div.attrs({
  id: "company-name-title"
})`
  color: white;
  font-size: 18px;
  position: relative;
  z-index: 10;
`;

export const CompanySubtitle = styled.h6.attrs({
  id: "company-sub-title"
})`
  color: white;
  position: relative;
  z-index: 10;
  font-size: 18px;
  line-height: 20px;
  margin-top: 15px;
`;

export const TradeMarkTextWrapper = styled.div.attrs({
  id: "trade-mark-text-wrapper"
})`
  color: white;
  position: absolute;
  z-index: 20;
  bottom: 0.75rem;
  font-size: ${props => props.theme.fontSizes.small85};
  font-weight: 400;
`;
