import React, { CSSProperties } from "react";
import styled from "styled-components";

type Props = {
  width?: number | string;
  height?: number | string;
  logoColor?: string;
  style?: CSSProperties;
};

const CompanyLogoWrapper = styled.div.attrs({
  id: "company-logo-wrapper"
})`
  display: grid;
  align-items: center;
`;

const CompanyLogo = styled.svg(
  (props: { width?: number | string; height?: number | string; style?: CSSProperties }) => ({
    id: "company-logo",
    width: props.width || "auto",
    height: props.height || "auto",
    ...(props.style ? props.style : {})
  })
);

const CleenLogo = (props: Props) => {
  return (
    <CompanyLogoWrapper>
      <CompanyLogo
        width={props.width}
        height={props.height}
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        viewBox="0 0 78 16"
      >
        <g fill={props.logoColor || "#666666"} fillRule="evenodd">
          <path
            fillRule="nonzero"
            d="M63.108 14.659V.662l11.426 9.998V1.549h2.368v13.909l-11.426-9.98v9.18z"
          />
          <path d="M57.377 1.629l-5.305 5.61h3.589v1.39h-6.167v4.717h6.167v1.392h-7.83v-2.98l.002-10.13h9.544zm-7.883 6.095l4.449-4.704h-4.449v4.704zM42.832 1.71l-5.305 5.61h3.588v1.391h-6.167v4.717h6.167v1.392h-7.83l.002-13.11h9.545zm-7.883 1.391l-.001 4.705 4.45-4.705h-4.45z" />
          <path
            fillRule="nonzero"
            d="M22.621 1.55v11.248h4.612v1.86h-6.98V1.55zM12.77 2.262v2.33c-1.349-.957-2.762-1.426-4.237-1.426-1.6 0-2.95.487-4.05 1.443-1.122.974-1.684 2.139-1.684 3.53 0 1.39.562 2.538 1.683 3.494 1.1.939 2.451 1.409 4.072 1.409.831 0 1.537-.105 2.12-.33a6.52 6.52 0 0 0 .996-.47 9.093 9.093 0 0 0 1.1-.696v2.382a9.768 9.768 0 0 1-4.257.974c-2.223 0-4.135-.661-5.714-1.965C1.221 11.616.431 10.033.431 8.156c0-1.67.645-3.147 1.974-4.451 1.62-1.6 3.719-2.417 6.294-2.417 1.413 0 2.763.33 4.072.974z"
          />
        </g>
      </CompanyLogo>
    </CompanyLogoWrapper>
  );
};

export default CleenLogo;
