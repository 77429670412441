import { changePassword, getUserErrorMessage, resetPasswordPolicy } from "client/services/api";
import * as apiIndependentFunctions from "client/services/apiFunctions";
import { decodeBase64 } from "common/utils";
import queryString from "query-string";
import React from "react";
import { getRuleExpanded, openNotificationWithIcon } from "../../../../utils";
import { AuthPageSubTitle, PageHeader, PageTitleWrapper } from "../../styles";
import { AuthPageTemplate } from "../Common/AuthPageTemplate";
import CleenLogo from "../Common/CleenLogo";
import { PasswordPolicies, ResetPasswordForm } from "./ResetPasswordForm";

export enum NotificationTypes {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error"
}

type State = {
  formSubmitted: boolean;
  pageIsLoading: boolean;
  email: string;
  query: string;
  passwordPolicies: PasswordPolicies | null;
};

type Props = {
  history;
  location;
};

const pageTitle = (
  <PageTitleWrapper>
    <PageHeader>Welcome to </PageHeader>
    <CleenLogo style={{ marginBottom: "2px" }} width={123} height={28} />
  </PageTitleWrapper>
);

const pageSubTitle = <AuthPageSubTitle>Please set a new password</AuthPageSubTitle>;

export default class ResetPassword extends React.Component<Props, State> {
  state: State = {
    formSubmitted: false,
    pageIsLoading: false,
    email: "",
    query: "",
    passwordPolicies: null
  };

  componentDidMount() {
    this.setState(
      {
        pageIsLoading: true
      },
      async () => {
        const query = decodeBase64(this.props.location.search);
        const queryParams = queryString.parse(query);
        const email: string = (queryParams.u as string) || "";
        const token: string = queryParams.token as string;
        try {
          if (token) {
            const policy = await resetPasswordPolicy(email, token);

            if (policy) {
              const rules: { key: string; value: number }[] = [];
              rules.push({
                key: "minPasswordLength",
                value: policy.passwordPolicySettings.minPasswordLength.toString()
              });
              if (policy.passwordPolicySettings.minCapital === 1) {
                rules.push({
                  key: "minCapital",
                  value: policy.passwordPolicySettings.minCapital
                });
              }
              if (policy.passwordPolicySettings.minNumber === 1) {
                rules.push({
                  key: "minNumber",
                  value: policy.passwordPolicySettings.minNumber
                });
              }
              if (policy.passwordPolicySettings.specialCharacters === 1) {
                rules.push({
                  key: "specialCharacters",
                  value: policy.passwordPolicySettings.specialCharacters
                });
              }
              const passwordPolicies = getRuleExpanded(rules);
              this.setState({
                passwordPolicies
              });
            }
          } else {
            throw new Error();
          }
          this.setState({
            pageIsLoading: false,
            email,
            query
          });
        } catch (err) {
          window.location.href = "/reset-password-expired";
        }
      }
    );
  }

  resetPassword = async (emailOrUsername: string, newPassword: string, confirmPassword: string) => {
    this.setState(
      () => {
        return {
          formSubmitted: true
        };
      },
      async () => {
        const { query } = this.state;
        try {
          await changePassword(emailOrUsername, newPassword, confirmPassword, query, undefined);
          openNotificationWithIcon(
            NotificationTypes.SUCCESS,
            "Reset Password Successful",
            "You have successfully reset your password, go ahead and login."
          );
          this.setState(
            {
              query: ""
            },
            () => {
              this.props.history.push("/login");
            }
          );
        } catch (err) {
          let title: string = "Reset Password Failed";
          let errorMessage: string = "";
          let showErrorNotification = true;
          if (err) {
            if (apiIndependentFunctions.isUserError(err)) {
              errorMessage = getUserErrorMessage(err);
              switch (err.errName) {
                case "UserBlockedError":
                  title = "You have been blocked";
                  break;
                case "PasswordResetLinkExpiredError":
                  title = "Password reset link has expired";
                  break;
                case "PasswordAlreadyUsed":
                  title = "Password already used";
                  break;
                default:
                  showErrorNotification = false;
              }
            } else {
              let description: string = "";

              switch (err.name) {
                case "InvalidToken":
                  description =
                    "Please make sure you have used a valid reset password link that is not older than 24 hours, Otherwise please go ahead and reset it again.";
                  break;
                case "InvalidPassword":
                default:
                  description = "Please make sure your password consists of atleast 8 characters.";
                  break;
              }
              errorMessage = description || err.message;
            }
          }
          if (showErrorNotification) {
            openNotificationWithIcon(NotificationTypes.ERROR, title, errorMessage);
          }
        }
      }
    );
  };

  render() {
    const { pageIsLoading, passwordPolicies } = this.state;
    return (
      <AuthPageTemplate
        title={pageTitle}
        subTitle={pageSubTitle}
        isLoading={pageIsLoading}
        pageForm={
          passwordPolicies ? (
            <ResetPasswordForm
              email={this.state.email}
              isLoading={this.state.pageIsLoading}
              onSubmit={this.resetPassword}
              passwordPolicies={passwordPolicies}
            />
          ) : undefined
        }
      />
    );
  }
}
