import styled from "styled-components";
import { Form, Button } from "antd";

export const AuthForm = styled(Form).attrs({
  id: "auth-form"
})`
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
`;

export const LoginFormButton = styled(Button).attrs({
  id: "login-form-button"
})`
  float: right;
  border-radius: 4px;
  height: 2.6rem;
  background-color: ${props => props.theme.alphaColors.dodgerBlue()} !important;
  color: ${props => props.theme.colors.white} !important;
  border-color: ${props => props.theme.alphaColors.white("0.58")} !important;
  letter-spacing: 0.03rem;
`;

export const PageTitleWrapper = styled.div.attrs({
  id: "page-title-wrapper"
})`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  grid-column-gap: 10px;
`;

export const PageHeader = styled.div.attrs({
  id: "page-header"
})`
  font-size: 1.5rem;
  margin-bottom: 0px;
  font-weight: 600;
  color: ${props => props.theme.colors.doveGray};
`;

export const AuthPageSubTitle = styled.p.attrs({
  id: "auth-sub-title"
})`
  color: ${props => props.theme.colors.doveGray};
  margin-top: 10px;
  margin-bottom: 1rem;
`;
