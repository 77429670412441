import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// import Page404 from "./views/theme/pages/404";

import SignIn from "./scenes/SignIn";
import ForgotPassword from "./scenes/ForgotPassword";
import ResetPassword from "./scenes/ResetPassword";
import PasswordExpiry from "./scenes/PasswordExpiry";
import RegisterUser from "./scenes/RegisterUser";
// import ChangePassword from "./scenes/ChangePassword";

const routes = props => (
  <Switch>
    <Route
      exact={true}
      render={routeProps => <SignIn handleLogin={props.handleLogin} {...routeProps} />}
      path="/"
    />
    <Route
      exact={true}
      render={routeProps => <SignIn handleLogin={props.handleLogin} {...routeProps} />}
      path="/sign-in"
    />
    <Route
      exact={true}
      render={routeProps => <ForgotPassword {...routeProps} />}
      path="/forgot-password"
    />
    <Route
      exact={true}
      render={routeProps => <PasswordExpiry {...routeProps} />}
      path="/reset-password-expired"
    />
    <Route
      exact={true}
      render={routeProps => <ResetPassword {...routeProps} {...props} />}
      path="/reset-password"
    />
    <Route exact={true} render={routeProps => <RegisterUser {...routeProps} />} path="/register" />
    {/* <Route
      exact
      render={routeProps => <ChangePassword {...routeProps} {...props} />}
      path="/change-password"
    /> */}
    {/* <Route path="/404" component={Page404} />*/}
    <Redirect from="*" to="/" />
  </Switch>
);

export default routes;
