import { Card, Col, Row, Spin } from "antd";
import { DimensionWrapper } from "client/styleUtils";
import React from "react";
import styled from "styled-components";

import LeftScreen from "./LeftScreen";

const FluidContainer = styled.div.attrs({
  id: "fluid-container"
})`
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  width: 100%;

  .ant-form-item-label label {
    font-weight: 700 !important;
  }
`;

const PageIsLoadingWrapper = styled.div.attrs({
  id: "page-loading-wrapper"
})`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

type Props = {
  isLoading?: boolean;
  title?: React.ReactElement;
  subTitle?: React.ReactElement;
  pageForm?: React.ComponentElement<any, any> | JSX.Element;
};

export const AuthPageTemplate = (props: Props) => {
  return (
    <FluidContainer>
      {
        <DimensionWrapper as={Row} height="100%">
          <DimensionWrapper as={Col} xl={8} lg={8} md={24} height="100%">
            <LeftScreen />
          </DimensionWrapper>
          <DimensionWrapper as={Col} xl={16} lg={16} md={24} height="100%">
            <DimensionWrapper
              as={Row}
              justify="center"
              align="middle"
              type="flex"
              height="100%"
              width="100%"
            >
              <Col span={11}>
                <Card className="card" style={{ boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                  {props.isLoading ? (
                    <PageIsLoadingWrapper>
                      <Spin />
                    </PageIsLoadingWrapper>
                  ) : (
                    <>
                      {props.title ? props.title : null}
                      {props.subTitle ? props.subTitle : null}
                      {props.pageForm ? props.pageForm : null}
                    </>
                  )}
                </Card>
              </Col>
            </DimensionWrapper>
          </DimensionWrapper>
        </DimensionWrapper>
      }
    </FluidContainer>
  );
};
