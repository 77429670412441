import { QuantityJSON, UUIDType } from ".";
import { ProductionMappingType } from "./equipment";

export type ProductionEquipmentDetailsWithLimitsAndWorstCase = {
  id: number;
  externalId: string;
  name: string;
  isProductWorst?: boolean;
  sal?: QuantityJSON;
  swabLimit?: QuantityJSON;
  rinseLimit?: QuantityJSON;
  type: ProductionMappingType;
  noOfEquipmentsBeingUsed?: number;
  equipments?: {
    id: number;
    externalId: string;
    name: string;
    isProductWorst?: boolean;
    sal?: QuantityJSON;
    swabLimit?: QuantityJSON;
    rinseLimit?: QuantityJSON;
  }[];
};

export enum ProductionType {
  PRODUCT = "product",
  INTERMEDIATE = "intermediate",
  API = "api"
}

export enum ProductionTypeVisual {
  product = "Product",
  intermediate = "Intermediate",
  api = "API"
}

export type PGProductDetailsType = {
  id: number;
  name: string;
  externalId: string;
  productionExternalIds: string[];
  isProductWorst?: boolean;
};

export type EqProductionDetailsType = {
  id: number;
  externalId: string;
  bs: QuantityJSON;
  isWorst?: boolean;
  sal?: QuantityJSON;
  productionType: ProductionType;
  product: {
    id: number;
    name: string;
    externalId: string;
  };
};

export type EqProductDetailsType = {
  name: string;
  productionType: ProductionType;
  id: number;
  externalId: string;
  productions: { externalId: string; bs: QuantityJSON }[];
  productionExternalIds: string[];
  minBs: QuantityJSON;
  isWorst?: boolean;
  sal?: QuantityJSON;
  acceptanceLimit?: QuantityJSON;
};

export type EqCleaningAgentDetailsType = {
  name: string;
  id: number;
  externalId: string;
  gras: boolean;
  sal?: QuantityJSON;
  swabLimit?: QuantityJSON;
  rinseLimit?: QuantityJSON;
};

export type ExistingProductionApiAMMapping = {
  [apiId: string]: { id?: number; apiName: string; amId?: UUIDType };
};

export type NewProductionApiAMMapping = {
  [apiId: string]: { apiName: string; amId?: UUIDType; amMethodId?: string };
};
