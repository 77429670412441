import { Button, Form, Icon, Input } from "antd";
import { FormComponentProps, FormCreateOption } from "antd/lib/form/Form";
import {
  containsAtleastOneCapitalLetter,
  hasSpecialCharacter,
  isNumeric,
  isUserPasswordOfMinLength,
  openNotificationWithIcon
} from "client/utils";
import { PasswordPolicyConfiguration } from "common/enums";
import React from "react";
import styled from "styled-components";

const FormItem = Form.Item;

export type PasswordPolicies = {
  key: string;
  value: number;
  meaning: string;
  isPassing: boolean;
}[];

type Props = {
  isLoading: boolean;
  email: string;
  onSubmit: (email: string, newPassword: string, confirmPassword: string) => Promise<void>;
  passwordPolicies: PasswordPolicies;
};

type State = {
  resetPasswordBtnLoading: boolean;
  policyMet: boolean;
  newPassword: string;
  confirmPassword: string;
  passwordPolicies: PasswordPolicies;
};

const RulesCheckBox = styled.span.attrs({
  id: "rules-checkbox"
})`
  background-color: ${(props: { ruleIsPassing: boolean }) =>
    props.ruleIsPassing ? "#00a69e" : "#bababa"};
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
`;

const RulesContainer = styled.div.attrs({
  id: "rules-container"
})`
  display: grid;
  grid-template-columns: 50% 50%;
`;

class ResetPasswordFormComponent extends React.Component<Props & FormComponentProps, State> {
  state: State = {
    resetPasswordBtnLoading: false,
    policyMet: false,
    passwordPolicies: [...this.props.passwordPolicies],
    newPassword: "",
    confirmPassword: ""
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { isLoading } = props;
    const { resetPasswordBtnLoading } = state;
    if (resetPasswordBtnLoading !== isLoading) {
      return {
        resetPasswordBtnLoading: isLoading
      };
    }
    return null;
  }

  resetRules = () => {
    const rulesStates = this.state.passwordPolicies;

    for (let i = 0; i < Object.keys(rulesStates).length; i++) {
      rulesStates[i].isPassing = false;
    }

    this.setState({
      passwordPolicies: rulesStates,
      policyMet: false
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { policyMet } = this.state;
    if (policyMet) {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          await this.props.onSubmit(
            values.emailOrUsername,
            values.newPassword,
            values.confirmPassword
          );
          this.props.form.resetFields(["newPassword", "confirmPassword"]);
          this.resetRules();
        }
      });
    } else {
      openNotificationWithIcon(
        "error",
        "Password Requirements",
        "Not all password requirements met"
      );
    }
  };

  handleChange = (newPassword: string, confirmPassword: string) => {
    const rulesStates = this.state.passwordPolicies;

    for (let i = 0; i < Object.keys(rulesStates).length; i++) {
      if (rulesStates[i].key === PasswordPolicyConfiguration.MIN_NUMBER) {
        rulesStates[i].isPassing = isNumeric(newPassword);
      } else if (rulesStates[i].key === PasswordPolicyConfiguration.MIN_CAPITAL) {
        rulesStates[i].isPassing = containsAtleastOneCapitalLetter(newPassword);
      } else if (rulesStates[i].key === PasswordPolicyConfiguration.PASSWORD_MATCH) {
        rulesStates[i].isPassing = newPassword === confirmPassword && newPassword !== "";
      } else if (rulesStates[i].key === PasswordPolicyConfiguration.MIN_PASSWORD_LENGTH) {
        rulesStates[i].isPassing = isUserPasswordOfMinLength(newPassword, rulesStates[i].value);
      } else if (rulesStates[i].key === PasswordPolicyConfiguration.SPECIAL_CHAR) {
        rulesStates[i].isPassing = hasSpecialCharacter(newPassword);
      }
    }

    const isPolicyFailing = rulesStates.some(r => !r.isPassing);

    this.setState({
      passwordPolicies: rulesStates,
      policyMet: !isPolicyFailing
    });
  };

  changeComparePassword = e => {
    const confirmPassword = e.target.value;
    this.setState({ confirmPassword });
    this.handleChange(this.state.newPassword, confirmPassword);
  };

  changeNewPassword = e => {
    const newPassword = e.target.value;
    this.setState({ newPassword });
    this.handleChange(newPassword, this.state.confirmPassword);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { resetPasswordBtnLoading } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem label="YOUR EMAIL/EMPLOYEE ID" required={false} colon={false}>
          {getFieldDecorator("emailOrUsername", {
            initialValue: this.props.email,
            rules: [
              {
                required: true,
                message: "Invalid Email/ Employee Id"
              }
            ]
          })(
            <Input
              disabled={true}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Your registered Email/Employee ID"
            />
          )}
        </FormItem>

        <FormItem label="PASSWORD" required={false} colon={false}>
          {getFieldDecorator("newPassword", {
            rules: [{ required: true, message: "Please enter your new password!" }]
          })(
            <Input
              onChange={this.changeNewPassword}
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Enter a unique password for your account"
            />
          )}
        </FormItem>

        <FormItem label="RE-ENTER PASSWORD" required={false} colon={false}>
          {getFieldDecorator("confirmPassword", {
            rules: [{ required: true, message: "Please confirm your password!" }]
          })(
            <Input
              onChange={this.changeComparePassword}
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Re-enter a unique password for your account"
            />
          )}
        </FormItem>
        {this.state.passwordPolicies.length > 0 ? (
          <FormItem>
            <RulesContainer>
              {this.state.passwordPolicies.map(rule => (
                <div key={rule.key} style={{ lineHeight: "normal" }}>
                  <RulesCheckBox ruleIsPassing={rule.isPassing} />
                  {rule.meaning}
                </div>
              ))}
            </RulesContainer>
          </FormItem>
        ) : (
          <div />
        )}
        <div className="d-flex" style={{ marginTop: `50px` }}>
          <div className="mr-auto" />
          <div className="reset-buttons-container">
            <Button
              type="primary"
              htmlType="submit"
              loading={resetPasswordBtnLoading}
              disabled={!this.state.policyMet}
              className="login-form-button-reset"
            >
              Complete Registration
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

const createOptions: FormCreateOption<any> = {};
export const ResetPasswordForm = Form.create(createOptions)(ResetPasswordFormComponent);
